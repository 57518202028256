/*
  Project: Geschaeftsdruck Cewe
  Author: Ivan Kulas
 */

import './modules/scroll-top';
import '../../node_modules/bootstrap/dist/js/bootstrap.bundle.min';
import './modules/archive-banners';
import './modules/cat-permalink-base';
import './modules/info-sections';
