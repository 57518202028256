// Show Category

const bannerTitle = document.querySelector('#archive-cat-banner');
const azTab = document.querySelector('#pills-a-z-tab');
const gastroTab = document.querySelector('#pills-gastro-tab');
const bueroTab = document.querySelector('#pills-buero-tab');
const broschurenTab = document.querySelector('#pills-broschuren-tab');
const kalenderTab = document.querySelector('#pills-kalender-tab');
const kartenTab = document.querySelector('#pills-karten-tab');
const werbemittelTab = document.querySelector('#pills-werbemittel-tab');
const werbetechnikTab = document.querySelector('#pills-werbetechnik-tab');

function showCatTitle() {
  if (gastroTab.classList.contains('active')) {
    bannerTitle.innerHTML = '<strong>Gastronomie</strong>';
  }
  if (broschurenTab.classList.contains('active')) {
    bannerTitle.innerHTML = '<strong>Broschüren</strong>';
  }
  if (bueroTab.classList.contains('active')) {
    bannerTitle.innerHTML = '<strong>Bürobedarf</strong>';
  }
  if (kalenderTab.classList.contains('active')) {
    bannerTitle.innerHTML = '<strong>Kalender</strong>';
  }
  if (werbemittelTab.classList.contains('active')) {
    bannerTitle.innerHTML = '<strong>Werbemittel</strong>';
  }
  if (werbetechnikTab.classList.contains('active')) {
    bannerTitle.innerHTML = '<strong>Werbetechnik</strong>';
  }
  if (kalenderTab.classList.contains('active')) {
    bannerTitle.innerHTML = '<strong>Kalender</strong>';
  }
  if (kartenTab.classList.contains('active')) {
    bannerTitle.innerHTML = '<strong>Karten</strong>';
  }
  if (azTab.classList.contains('active')) {
    bannerTitle.innerHTML = '<strong>Alle Produkte von A bis Z</strong>';
  }
}

// load only after the page is loaded and the page is product page

window.addEventListener('load', function () {
  if (document.querySelector('.page-id-96')) {
    azTab.addEventListener('click', showCatTitle);
    gastroTab.addEventListener('click', showCatTitle);
    bueroTab.addEventListener('click', showCatTitle);
    broschurenTab.addEventListener('click', showCatTitle);
    kalenderTab.addEventListener('click', showCatTitle);
    kartenTab.addEventListener('click', showCatTitle);
    werbemittelTab.addEventListener('click', showCatTitle);
    werbetechnikTab.addEventListener('click', showCatTitle);
  }
});
