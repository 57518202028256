/*eslint-disable */
let links = document.getElementsByTagName("a");

for (let i = 0, l = links.length; i < l; i++) {
  let link = links[i];
  if (link.href.indexOf("/kategorie/") !== -1) {
    wrongLink = link.href;
    correctLink = wrongLink.replace('/kategorie', '');
    link.href = correctLink;
  }
}
/* eslint-enable */
